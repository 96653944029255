
import { SiweMessage } from 'siwe'
import { CONFIG } from '../config/app'

// =============================================================
//                              STYLES
// =============================================================

const errLogger = (e: any) => {
  // if (!PROD) console.log(e)
}

export const getSession = async (): Promise<Response | null> => {
  return await fetch(`${CONFIG.SERVER_URL}/signature/session`, {
    credentials: 'include',
  }).then(response => {
    return response
  }).catch(e => {
    errLogger(e)
    return null
  })
}

export const getSignout = async (): Promise<Response | null> => {
  return await fetch(`${CONFIG.SERVER_URL}/signature/logout`, {
    credentials: 'include',
    method: 'POST'
  }).then(response => {
    return response
  }).catch(e => {
    errLogger(e)
    return null
  })
}

export const getNonce = async (): Promise<Response | null> => {
  return await fetch(`${CONFIG.SERVER_URL}/signature/nonce`, {
    credentials: 'include',
  }).then(response => {
    return response
  }).catch(e => {
    errLogger(e)
    return null
  })
}

export const getProof = async (addy: string): Promise<Response | null> => {
  return await fetch(`${CONFIG.SERVER_URL}/merkle/proof?add=${addy}`, {
    credentials: 'include',
  }).then(response => {
    return response
  }).catch(e => {
    errLogger(e)
    return null
  })
}

export const verifySignature = async (signature: string, message: SiweMessage): Promise<Response | null> => {
  return await fetch(`${CONFIG.SERVER_URL}/signature/verify`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      message: message,
      signature: signature
    })
  }).then(response => {
    return response
  }).catch(e => {
    errLogger(e)
    return null
  })
}