import { lazy } from 'react';

export const delayImport = (ms: number = 500, fn?: any) => lazy(async () => {
  await new Promise(resolve => setTimeout(resolve, ms));

  if (fn) return fn
  else return true
})


export const delay = async (ms: number = 500) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}