import { createPublicClient, createWalletClient, webSocket } from 'viem'
import { mainnet, sepolia } from 'viem/chains'
import { CONFIG } from '../config/app'


const pickChain = (chain: string) => {
  switch (chain) {
    case '1':
      return mainnet
    case '11155111':
      return sepolia
  }
}

export const chain = pickChain(CONFIG.CHAIN_ID)

export const publicClient = createPublicClient({
  batch: {
    multicall: true,
  },
  chain: pickChain(CONFIG.CHAIN_ID),
  transport: webSocket(CONFIG.CHAIN_PROVIDER)
})

export const walletClient = createWalletClient({
  chain: pickChain(CONFIG.CHAIN_ID),
  transport: webSocket(CONFIG.CHAIN_PROVIDER)
})