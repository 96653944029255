import React from 'react';

// =============================================================
//                          LOCAL
// =============================================================

import { P } from '@common/p';
import { Logo } from '@icons';
import { Section } from '@common/section';

// =============================================================
//                          COMPONENT
// =============================================================

export const System = ({ label }: { label: string }) => {
  return (<div style={{ padding: '16px' }}>
    <Logo size={68} />
    <P theme='accent'>{label}</P>
  </div>)
}