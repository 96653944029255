// db.ts
import Dexie, { Table } from 'dexie';

export interface Blob {
  id?: number
  address: `0x${string}`;
  blob: string;
}

export interface Session {
  id?: number
  timestamp: number;
  blob: string;
}

export interface TerminalMessage {
  id?: number
  author: string;
  data: string;
  type: OS.Theme;
}

export class Grid extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  blobs!: Table<Blob>;
  sessions!: Table<Session>;
  terminal!: Table<TerminalMessage>;

  constructor() {
    super('Grid');
    this.version(1).stores({
      blobs: '++id,address, blob', // Primary key and indexed props
      sessions: '++id,timestamp, blob', // Primary key and indexed props
      terminal: '++id, author, type, data'
    });
  }
}

export const db = new Grid();