// https://geekpaul.medium.com/system-design-building-an-in-memory-key-value-store-js-4d3aa9aec31c

interface MemoryStore {
  [key: string]: string | undefined
}

class KeyValueStore {
  store: MemoryStore;

  constructor() {
    this.store = {};
  }

  get(key: string) {
    return this.store[key];
  }

  set(key: string, value: string) {
    this.store[key] = value;
  }

  delete(key: string) {
    delete this.store[key];
  }
}

export const memory = new KeyValueStore();