// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CGVOI5pLB6l6Bjjc7h3G {
  height: 32px;
  background-color: rgb(var(--c-accent-100));

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;
}

.yH0YWbA78fPV1JoyVfIW {
  font-size: var(--f-size-default);
  color: rgb(var(--c-accent-600));
  text-shadow: rgba(var(--c-accent-600), 0.7) 0 0 4px;
  padding-bottom: 2px;
}

.cQkH_0rCzt0Ktsw980nw {
  display: flex;
  align-items: center;
  padding-left: 8px;
  height: 100%;
  width: 100%;

  background: rgb(var(--c-accent-200));
  background: linear-gradient(90deg, rgb(var(--c-accent-300)) 0%, rgb(var(--c-accent-200)) 100%);
}

.F9WKAhvPOush40lF_rWS {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  height: 1px;
  width: 100%;
  background-color: rgb(var(--c-accent-400));
  margin: 0;
  padding: 0;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/header/header.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0CAA0C;;EAE1C,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,+BAA+B;EAC/B,mDAAmD;EACnD,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,WAAW;;EAEX,oCAAoC;EACpC,8FAA8F;AAChG;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,cAAc;EACd,WAAW;EACX,WAAW;EACX,0CAA0C;EAC1C,SAAS;EACT,UAAU;EACV,YAAY;AACd","sourcesContent":[".wrapper {\n  height: 32px;\n  background-color: rgb(var(--c-accent-100));\n\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-shrink: 0;\n  position: relative;\n}\n\n.title {\n  font-size: var(--f-size-default);\n  color: rgb(var(--c-accent-600));\n  text-shadow: rgba(var(--c-accent-600), 0.7) 0 0 4px;\n  padding-bottom: 2px;\n}\n\n.title_wrapper {\n  display: flex;\n  align-items: center;\n  padding-left: 8px;\n  height: 100%;\n  width: 100%;\n\n  background: rgb(var(--c-accent-200));\n  background: linear-gradient(90deg, rgb(var(--c-accent-300)) 0%, rgb(var(--c-accent-200)) 100%);\n}\n\n.separator {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  display: block;\n  height: 1px;\n  width: 100%;\n  background-color: rgb(var(--c-accent-400));\n  margin: 0;\n  padding: 0;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CGVOI5pLB6l6Bjjc7h3G`,
	"title": `yH0YWbA78fPV1JoyVfIW`,
	"title_wrapper": `cQkH_0rCzt0Ktsw980nw`,
	"separator": `F9WKAhvPOush40lF_rWS`
};
export default ___CSS_LOADER_EXPORT___;
