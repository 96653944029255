import * as React from 'react';

// =============================================================
//                          LOCAL
// =============================================================

import styles from './corner.module.css'

// =============================================================
//                          TYPINGS
// =============================================================

const Themes = {
  primary: styles.primary,
  secondary: styles.secondary,
  accent: styles.accent
}

type Position = 'bottom_left' | 'bottom_right' | 'top_left' | 'top_right'

const Rotation = {
  bottom_left: styles.rotation_bottom_left,
  bottom_right: styles.rotation_bottom_right,
  top_left: styles.rotation_top_left,
  top_right: styles.rotation_top_right,
}

const SpacingOffset = {
  bottom_left: styles.bottom_left_offset,
  bottom_right: styles.bottom_right_offset,
  top_left: styles.top_left_offset,
  top_right: styles.top_right_offset,
}

const SpacingNoOffset = {
  bottom_left: styles.bottom_left_no_offset,
  bottom_right: styles.bottom_right_no_offset,
  top_left: styles.top_left_no_offset,
  top_right: styles.top_right_no_offset,
}

interface CornerProps {
  position: Position
  theme?: OS.Theme,
  style?: React.CSSProperties
  offset?: boolean
}

// =============================================================
//                          COMPONENT
// =============================================================

export const Corner = (props: CornerProps) => {
  const offset = (props.offset == undefined || props.offset == true) ? true : false

  const classNames = [
    styles.corner,
    Rotation[props.position],
    offset ? SpacingOffset[props.position] : SpacingNoOffset[props.position],
    props.theme ? Themes[props.theme] : Themes['accent'],
  ].join(' ')

  return <svg style={{ ...props.style }} className={classNames} width={4} height={4} viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg">
    <rect width="4" height="1" />
    <rect width="1" height="4" />
  </svg>
}