import * as React from 'react';
import { Button as MUIButton, ButtonProps } from '@mui/base/Button';

// =============================================================
//                          LOCALS
// =============================================================

import styles from './button.module.css'
import { Corner } from '@common/corner'

// =============================================================
//                           TYPES
// =============================================================

export type ButtonLayouts = 'icon' | 'label' | 'icon_label' | 'label_icon'

export const Themes = {
  primary: styles.primary,
  secondary: styles.secondary,
  accent: styles.accent
}

export const Layouts = {
  icon: styles.icon,
  label: styles.label,
  icon_label: styles.icon_label,
  label_icon: styles.label_icon
}

interface IButton extends ButtonProps {
  theme?: OS.Theme,
  layout?: ButtonLayouts
  innerref?: React.MutableRefObject<HTMLButtonElement | null>
  disabled?: boolean
}

// =============================================================
//                          COMPONENT
// =============================================================

export const Button = (props: IButton) => {
  const { layout, ...muiProps } = props

  const themeSelected = props.theme || 'accent'
  const layoutSelected = layout || 'label'
  const disabled = props.disabled ? true : false

  const classNames = [
    styles.button_wrapper,
    Themes[themeSelected],
    Layouts[layoutSelected],
    disabled ? styles.button_disabled : null,
    props.className
  ].join(' ')

  return (
    <MUIButton className={classNames} {...muiProps} ref={props.innerref} disabled={disabled}>
      <Corner position='bottom_left' theme={themeSelected} />

      {props.children}
    </MUIButton >
  );
}