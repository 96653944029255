// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DwsYUvSFHEDmbOgEBKm0 {
  color: rgba(var(--c-accent-600));
  fill: rgba(var(--c-accent-600));
}

.WkCJfEZaEi02VDBJYkfC {
  color: rgba(var(--c-primary-600));
  fill: rgba(var(--c-primary-600));
}

.lDMLK3sFRkmJ3zDXgW7f {
  color: rgba(var(--c-secondary-600));
  fill: rgba(var(--c-secondary-600));
}`, "",{"version":3,"sources":["webpack://./src/icons/icons.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,+BAA+B;AACjC;;AAEA;EACE,iCAAiC;EACjC,gCAAgC;AAClC;;AAEA;EACE,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":[".accent {\n  color: rgba(var(--c-accent-600));\n  fill: rgba(var(--c-accent-600));\n}\n\n.primary {\n  color: rgba(var(--c-primary-600));\n  fill: rgba(var(--c-primary-600));\n}\n\n.secondary {\n  color: rgba(var(--c-secondary-600));\n  fill: rgba(var(--c-secondary-600));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accent": `DwsYUvSFHEDmbOgEBKm0`,
	"primary": `WkCJfEZaEi02VDBJYkfC`,
	"secondary": `lDMLK3sFRkmJ3zDXgW7f`
};
export default ___CSS_LOADER_EXPORT___;
