// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tviSBExQMpifeTXXUVg2 {
  font-size: var(--f-size-default);
  line-height: var(--f-height-default);
}

.K4JWCxi3TRos9KHXld3e {
  color: rgb(var(--c-accent-600));
  text-shadow: rgba(var(--c-accent-600), 0.7) 0 0 4px;

  a {
    color: rgb(var(--c-accent-600));
  }
}

.ZFAPJhyjtqgSGGyfojyD {
  color: rgb(var(--c-primary-600));
  text-shadow: rgba(var(--c-primary-600), 0.7) 0 0 4px;

  a {
    color: rgb(var(--c-primary-600));
  }
}

.K1MDT9dK4akVGlOY_8Pm {
  color: rgb(var(--c-secondary-600));
  text-shadow: rgba(var(--c-secondary-600), 0.7) 0 0 4px;

  a {
    color: rgb(var(--c-secondary-600));
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/p/p.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,oCAAoC;AACtC;;AAEA;EACE,+BAA+B;EAC/B,mDAAmD;;EAEnD;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE,gCAAgC;EAChC,oDAAoD;;EAEpD;IACE,gCAAgC;EAClC;AACF;;AAEA;EACE,kCAAkC;EAClC,sDAAsD;;EAEtD;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".paragraph {\n  font-size: var(--f-size-default);\n  line-height: var(--f-height-default);\n}\n\n.accent {\n  color: rgb(var(--c-accent-600));\n  text-shadow: rgba(var(--c-accent-600), 0.7) 0 0 4px;\n\n  a {\n    color: rgb(var(--c-accent-600));\n  }\n}\n\n.primary {\n  color: rgb(var(--c-primary-600));\n  text-shadow: rgba(var(--c-primary-600), 0.7) 0 0 4px;\n\n  a {\n    color: rgb(var(--c-primary-600));\n  }\n}\n\n.secondary {\n  color: rgb(var(--c-secondary-600));\n  text-shadow: rgba(var(--c-secondary-600), 0.7) 0 0 4px;\n\n  a {\n    color: rgb(var(--c-secondary-600));\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `tviSBExQMpifeTXXUVg2`,
	"accent": `K4JWCxi3TRos9KHXld3e`,
	"primary": `ZFAPJhyjtqgSGGyfojyD`,
	"secondary": `K1MDT9dK4akVGlOY_8Pm`
};
export default ___CSS_LOADER_EXPORT___;
