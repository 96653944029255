import dotenv from "dotenv";

const logAndExit = (label: string, msg?: string | boolean) => {
  if (msg == undefined) {
    console.log(`%c [ No ${label} provided! ]`, 'background: #f00; color: #fff');
    return false
  }

  return true
}

export const CONFIG = {
  ENV: process.env.NODE_ENV === "production",

  CLIENT_MAINTENANCE: process.env.MAINTENANCE as string,
  CLIENT_URL: process.env.URL_CLIENT as string,

  SERVER_URL: process.env.URL_SERVER as string,
  SESSION_KEY: process.env.SESSION_KEY as string,

  CHAIN_ID: process.env.CHAIN_ID as string,
  CHAIN_PROVIDER: process.env.CHAIN_PROVIDER as string,

  CONTRACT_MC: process.env.CONTRACT_MC as `0x${string}`,
  CONTRACT_GIGA: process.env.CONTRACT_MC as `0x${string}`,
}

const processConfig = () => {
  let correctConfig = true

  Object
    .entries(CONFIG)
    .forEach((configItem) => {
      const [name, value] = configItem
      logAndExit(name, value)

      if (value === undefined) correctConfig = false
    })

  return correctConfig
}

export const VALID_CONFIG = processConfig()