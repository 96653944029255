import React, { useState, createContext, useEffect, useContext } from 'react';

// =============================================================
//                          LOCALS
// =============================================================

import { db, TerminalMessage } from '@utils/db';
import { AccountContext } from './accountContext';
import { asyncForEach } from '@utils/asyncForEach';

// =============================================================
//                          TYPINGS
// =============================================================

interface ISaveMessage {
  author?: string,
  message: string,
  type?: OS.Theme
}

export interface ITerminalContext {
  messages: TerminalMessage[]
  sendMessage(message: ISaveMessage | ISaveMessage[]): Promise<void>
  clearMessages(): Promise<void>
}

export const defaultTerminalContext: ITerminalContext = {
  messages: [],
  sendMessage: async (message: ISaveMessage | ISaveMessage[]) => { },
  clearMessages: async () => { }
}

interface ITerminalContextProps {
  children: any
}

const shortenAddress = (address: string) => {
  return address.slice(0, 7)
}

// =============================================================
//                            CONTEXT
// =============================================================

export const TerminalContext = createContext(defaultTerminalContext)

export const TerminalConsumer = TerminalContext.Consumer

export const defaultMessage: TerminalMessage[] = [{
  author: '0x00000',
  data: 'Welcome to Giga/OS citizen.',
  type: 'accent'
}, {
  author: '0x00000',
  data: '',
  type: 'accent'
}, {
  author: '0x00000',
  data: 'To commence interaction with our operating system, type "help" to see the list of available commands.',
  type: 'accent'
}, {
  author: '0x00000',
  data: '',
  type: 'accent'
}, {
  author: '0x00000',
  data: 'Giga City (c) 2089',
  type: 'accent'
}]

// =============================================================
//                         COMPONENT
// =============================================================

export const TerminalProvider = (props: ITerminalContextProps) => {
  const [messages, setMessages] = useState<TerminalMessage[]>([])
  const { account } = useContext(AccountContext)

  useEffect(() => {
    const getMessages = async () => {
      let updatedMessages = [...(await db.terminal.toCollection().toArray())]

      if (updatedMessages.length === 0) {
        updatedMessages = defaultMessage
        await db.terminal.bulkAdd(defaultMessage)
      }

      setMessages(updatedMessages)
    }

    getMessages()
  }, [])

  const sendMessage = async (message: ISaveMessage | ISaveMessage[]) => {
    if (Array.isArray(message)) {
      let messagesToState: TerminalMessage[] = []

      await asyncForEach(message, async (msg: ISaveMessage) => {
        const author = shortenAddress(msg.author || account?.address || '0x00000')
        const type = msg.type || 'accent'

        const tempMessage = {
          author: author,
          data: msg.message,
          type: type
        }

        await db.terminal.put(tempMessage);
        messagesToState.push(tempMessage)
      })

      setMessages([...messages, ...messagesToState])
    } else {
      const author = shortenAddress(message.author || account?.address || '0x00000')
      const type = message.type || 'accent'

      await db.terminal.put({
        author: author,
        data: message.message,
        type: type
      });

      setMessages([...messages, {
        author: author,
        data: message.message,
        type: type
      }])
    }
  }

  const clearMessages = async () => {
    await db.terminal.clear()
    await db.terminal.bulkAdd(defaultMessage)
    setMessages(defaultMessage)
  }


  return (
    <TerminalContext.Provider value={{
      messages,
      sendMessage,
      clearMessages
    }}>
      {props.children}
    </TerminalContext.Provider>
  )
}