import React from 'react'

// =============================================================
//                          LOCAL
// =============================================================

import styles from './loader.module.css'

// =============================================================
//                          TYPINGS
// =============================================================

interface Loader {
  fullPage?: boolean
  width?: string;
  height?: string;
  theme?: OS.Theme,
}

const Themes = {
  primary: styles.primary,
  secondary: styles.secondary,
  accent: styles.accent
}

// =============================================================
//                          COMPONENTS
// =============================================================

const FullPage = (props: any) => {
  return (<div className={styles.loaderFullPage}>{props.children}</div>)
}

export const Loader = (props: Loader) => {
  const width = props.width || '80px'
  const height = props.height || '80px'
  const themeSelected = props.theme || 'accent'
  const Wrapper = props.fullPage ? FullPage : React.Fragment

  const classNames = [
    styles.loaderCenterContent,
    Themes[themeSelected],
  ].join(' ')

  return (
    <Wrapper>
      <div style={{ width, height }} className={classNames}>
        <div className={[styles.loaderBar, styles.loaderBarFirst].join(' ')} />
        <div className={[styles.loaderBar, styles.loaderBarSecond].join(' ')} />
        <div className={[styles.loaderBar, styles.loaderBarThird].join(' ')} />
      </div>
    </Wrapper>
  )
}
