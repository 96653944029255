import React from "react";

// =============================================================
//                            INTERNAL
// =============================================================

import styles from './header.module.css'
import { Button } from "@common/button";
import { Close } from "@icons";

// =============================================================
//                            TYPINGS
// =============================================================

interface IStatusBar {
  title: string
  closeAction?: Function
}

// =============================================================
//                           COMPONENT
// =============================================================

export const Header = (props: IStatusBar) => {
  const { title, closeAction } = props

  return (<div className={styles.wrapper}>
    <hr className={styles.separator} />

    <div className={`appHeader ${styles.title_wrapper}`}>
      <span className={styles.title}>{title}</span>
    </div>

    {closeAction && (
      <Button layout='icon' onClick={(e) => {
        closeAction(e)
      }}>
        <Close />
      </Button>
    )}

  </div>)
}