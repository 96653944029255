import * as React from 'react';
import { Modal as BaseModal, ModalProps } from '@mui/base/Modal';

// =============================================================
//                          LOCAL
// =============================================================

import { Header } from "@common/header"
import { Corner } from '@common/corner'
import styles from './modal.module.css'

// =============================================================
//                          TYPINGS
// =============================================================

interface IModal extends ModalProps {
  title?: string
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props: any, ref) => {
  const { open, className, ownerState, ...other } = props;

  const classNames = [
    styles.backdrop,
    props.className
  ].join(' ')

  return (
    <div ref={ref} className={classNames} {...other} />
  )
})

// =============================================================
//                          COMPONENT
// =============================================================

export const Modal = (props: IModal) => {

  return (
    <BaseModal
      className={styles.wrapper}
      aria-labelledby={props['aria-labelledby']}
      aria-describedby={props['aria-describedby']}
      open={props.open}
      onClose={props.onClose}
      slots={{ backdrop: Backdrop }}
    >
      <div className={styles.box}>
        <Corner position='top_left' />
        <Corner position='top_right' />
        <Corner position='bottom_left' />
        <Corner position='bottom_right' />

        <Header
          title={props.title || ''}
          closeAction={(e: any) => { props.onClose ? props.onClose(e, 'escapeKeyDown') : null }}
        />

        <div className={styles.content}>
          {props.children}
        </div>
      </div>
    </BaseModal >
  )
} 