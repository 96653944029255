import React, { Suspense, useContext } from "react";
import { createRoot } from 'react-dom/client';

// =============================================================
//                            STYLING
// =============================================================

import 'overlayscrollbars/overlayscrollbars.css';

import "@styles/reset.css"
import "@styles/root.css"
import "@styles/index.css"

// =============================================================
//                           INTERNALS
// =============================================================

import '@utils/db'

import { AccountProvider } from "@context/accountContext"
import { OSContext, OSProvider } from "@context/osContext";
import { TerminalProvider } from "@context/terminalContext";
import { delayImport } from "@utils/delay";
import { Loader } from "@common/loader"
import { Modal } from "@common/modal";
import { CONFIG, VALID_CONFIG } from "src/config/app";
import { System } from "@components/common/modal/system";

// =============================================================
//                          LAZY LOADS
// =============================================================

const MobileView = delayImport(1200, import('@components/mobile/index'));
const DesktopView = delayImport(1200, import('@components/desktop/index'));

// =============================================================
//                             APP
// =============================================================

export const App = () => {
  const { viewport } = useContext(OSContext)

  if (VALID_CONFIG === false) {
    return <Suspense fallback={<Loader fullPage={true} />}>
      <Modal open={true} title="SYSTEM MESSAGE">
        <System label={'Grid is missing critical configuration.'} />
      </Modal>
    </Suspense>
  }

  if (VALID_CONFIG && CONFIG.CLIENT_MAINTENANCE === 'true') {
    return (<Suspense fallback={<Loader fullPage={true} />}>
      <Modal open={true} title="System Message">
        <System label={`Our grid is currently under maintenance. We'll be back shortly. Stand by citizen.`} />
      </Modal>
    </Suspense>)
  }

  if (viewport.isMobile) {
    return (<Suspense fallback={<Loader fullPage={true} />}><MobileView /></Suspense>)
  } else {
    return (<Suspense fallback={<Loader fullPage={true} />}><DesktopView /></Suspense>)
  }
}

// =============================================================
//                            RENDERER
// =============================================================

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  // <StrictMode>
  <AccountProvider>
    <OSProvider>
      <TerminalProvider>
        <div className='scanlines' />
        <App />
      </TerminalProvider>
    </OSProvider>
  </AccountProvider>
  // </StrictMode>
);