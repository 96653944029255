import { useEffect, useState } from "react";

import { debounce } from '@utils/debounce'

export function useWindowSize(mobileBreakpoint: number = 450) {
  // Initialize state with undefined width/height so server and client renders match
  // It is fine to initialize it in plain react app
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [windowSize, setWindowSize] = useState<{
    width: number, height: number
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= mobileBreakpoint);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      if (window.innerWidth <= mobileBreakpoint) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
    // Add event listener
    window.addEventListener("resize", debounce(handleResize, 100));
    // Call handler right away so state gets updated with initial window size
    // handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", debounce(handleResize, 100));
  }, []); // Empty array ensures that effect is only run on mount
  return {
    ...windowSize,
    isMobile
  };
}