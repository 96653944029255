import React, { useState, createContext } from 'react';

// =============================================================
//                            LOCALS
// =============================================================

import { useWindowSize } from "@hooks/useWindowSize";
import { Storage } from '@utils/storage'

// =============================================================
//                            TYPINGS
// =============================================================

export interface IOSContext {
  viewport: {
    width: number
    height: number
    isMobile: boolean
  }
  stack: {
    data: string[]
    set: (id: string, value: boolean) => void
    last: string,
    setLast: (id: string) => void
  }
}

interface IOSContextProps {
  children: any
}

// =============================================================
//                            DEFAULT
// =============================================================

export const defaultOSContext: IOSContext = {
  viewport: {
    width: 0,
    height: 0,
    isMobile: false
  },
  stack: {
    data: [],
    set: () => { },
    last: '',
    setLast: () => { }
  }
}

// =============================================================
//                            CONTEXT
// =============================================================

export const OSContext = createContext(defaultOSContext)

export const OSConsumer = OSContext.Consumer

// =============================================================
//                            PROVIDER
// =============================================================

export const OSProvider = (props: IOSContextProps) => {
  const { isMobile, width, height } = useWindowSize(500);

  const rawDefaultStack = localStorage.getItem(Storage.STACK_STORAGE)
  const defaultStack = rawDefaultStack ? JSON.parse(rawDefaultStack) : []

  const lastAppStorage = localStorage.getItem(Storage.STACK_LAST_APP)

  const [stack, setStack] = useState<string[]>(defaultStack)
  const [lastApp, setLastApp] = useState<string>(lastAppStorage || '')

  const lastHandler = (id: string) => {
    setLastApp(id)
    localStorage.setItem(Storage.STACK_LAST_APP, id)
  }

  const stackHandler = (id: string, newVisibility: boolean) => {
    const localStack = [...stack]
    const index = stack.indexOf(id)

    const alreadyVisible = localStack[localStack.length - 1] === id

    if (newVisibility) {
      if (index === -1) {
        localStack.push(id)
      } else {
        localStack.splice(index, 1)
        localStack.push(id)
      }
    } else {
      if (index > -1) {
        localStack.splice(index, 1)
      }
    }

    if (!alreadyVisible) {
      localStorage.setItem(Storage.STACK_STORAGE, JSON.stringify(localStack))
      setStack(localStack)
      lastHandler(id)
    }

    if (alreadyVisible && newVisibility === false) {
      localStorage.setItem(Storage.STACK_STORAGE, JSON.stringify(localStack))
      setStack(localStack)
      lastHandler(id)
    }
  }

  // =============================================================
  //                            PROVIDER
  // =============================================================

  return (
    <OSContext.Provider value={{
      viewport: {
        width,
        height,
        isMobile
      },
      stack: {
        data: stack,
        set: stackHandler,
        last: lastApp,
        setLast: lastHandler
      }
    }}>
      {props.children}
    </OSContext.Provider >
  )
}