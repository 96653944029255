import React, { useEffect, useState } from 'react';
import { createContext } from "react";
import { decrypt, encrypt } from '@metamask/browser-passworder'
import { privateKeyToAccount, generatePrivateKey, PrivateKeyAccount } from 'viem/accounts'
import { SiweMessage } from 'siwe'
import { formatEther } from 'viem';

// =============================================================
//                          LOCAL
// =============================================================

import { publicClient } from '@utils/web3';
import { memory } from '@utils/memory';
import { db, Blob } from '@utils/db';
import { CONFIG } from 'src/config/app';
import { getSession, getSignout, verifySignature } from '@utils/requests';
import { Storage } from '@utils/storage';
import { walletClient } from '@utils/web3';
import { useInterval } from '@hooks/useInterval';
import { timeStamp } from 'console';
// import { CHAIN}

// =============================================================
//                           CONFIG
// =============================================================

export const WALLET_KEY = 'wallet_key'

enum Errors {
  UNKNOWN_ERROR = 'Unknown error.',
  SERVER_UNAVAILABLE = "Connection to the grid is currently unavailable.",
  SOMETHING_WRONG = "An error has occurred within the grid's operations.",
  NO_WALLET_DATA = "No local wallet data records detected.",
  MISSING_DATA = "Required data not located in the grid archives.",
  MALFORMED_DATA = "Inconsistencies detected in your local data alignment.",
  WRONG_PASSWORD = "Access denied: incorrect authorization code."
}

const BLOB_ID = 0

// TODO: I NEED to differentiate wallet and account mixing up
// TODO: Wallet functions will be only for wallets
// TODO: ANd account functions, will be only for accounts
// TODO: I need to fix pottential leaks in the periodic session cheker

// =============================================================
//                            TYPINGS
// =============================================================

export type SessionState = 'loading' | 'no_account' | 'no_session' | 'active_session'

export interface IAccountContext {
  account: PrivateKeyAccount | null
  addressShort: string | null
  sessionState: SessionState
  lastAccount: `0x${string}` | null
  balance: number
  prestige: number
  getWallet(): Promise<Blob | undefined>
  exportWallet(password: string): Promise<[null | `0x${string}`, null | Errors]>;
  deleteWallet(): Promise<[null | boolean, null | Errors]>
  createAccount(password: string, privateKey?: `0x${string}`): Promise<[PrivateKeyAccount | null, null | Errors]>
  loginAccount(password: string, nonce: string): Promise<[PrivateKeyAccount | null, null | Errors]>
  logoutAccount(): Promise<[null | boolean, null | Errors]>
}

interface IBlob {
  address: `0x${string}`,
  privateKey: `0x${string}`,
}

interface IAccountContextProps {
  children: any
}

// =============================================================
//                            DEFAULT
// =============================================================

export const defaultAccountContext: IAccountContext = {
  account: null,
  addressShort: null,
  sessionState: 'no_session',
  lastAccount: null,
  balance: 0,
  prestige: 0,
  getWallet: async () => { return undefined },
  exportWallet: async (password: string) => { return [null, null] },
  deleteWallet: async () => { return [false, null] },
  createAccount: async (password: string) => { return [null, null] },
  loginAccount: async (password: string, nonce: string) => { return [null, null] },
  logoutAccount: async () => { return [false, null] }
}

// =============================================================
//                            CONTEXT
// =============================================================

export const AccountContext = createContext(defaultAccountContext)

export const AccountConsumer = AccountContext.Consumer

// =============================================================
//                            PROVIDER
// =============================================================

export const AccountProvider = (props: IAccountContextProps) => {
  const [addressShort, setAddressShort] = useState<string | null>(null);
  const [account, setAccount] = useState<PrivateKeyAccount | null>(null);
  const [sessionState, setSessionState] = useState<SessionState>('loading')
  const [lastAccount, setLastAccount] = useState<`0x${string}` | null>(null)
  const [balance, setBalance] = useState<number>(0)
  const [prestige, setPrestige] = useState<number>(0)

  // =============================================================
  //                       HELPERS
  // =============================================================

  const getShortAddress = (address: string) => {
    return address.substring(0, 6) + "..." + address.substring(address.length - 4, address.length)
  }

  const getAndSetBalance = async (account: PrivateKeyAccount | null) => {
    try {
      if (account === null) return
      const balance = await publicClient.getBalance({
        address: account.address
      })

      const ether = parseFloat(formatEther(balance))
      const ether_n = parseFloat(ether.toFixed(2))

      setBalance(ether_n)
      return ether_n
    } catch (e) {
      return null
    }
  }

  // =============================================================
  //                    ON LOAD SESSION VALIDATION
  // =============================================================


  const checkStatus = async () => {
    // We need to check whether we have blob and session
    const walletData = await getWallet()
    if (!walletData) {
      console.log('no_account')
      if (sessionState !== 'no_account') setSessionState('no_account')
      return
    }
    // If we do, we can set it as previous account
    setLastAccount(walletData.address)

    const walletSession = await restoreWalletSession()
    if (walletSession == null) {
      if (sessionState !== 'no_session') setSessionState('no_session')
      return
    }

    const serverSession = await getSession()
    if (serverSession === null || serverSession?.ok === false) {
      console.log('no_server_session')
      if (sessionState !== 'no_session') setSessionState('no_session')
      if (account !== null) setAccount(null)
      return
    } else {
      const session_body = await serverSession?.json()
      setPrestige(session_body.prestige)
    }

    if (sessionState !== 'active_session') setSessionState('active_session')

    await getAndSetBalance(walletSession)
  }

  useInterval(async () => {
    await checkStatus()
  }, 12000)

  useEffect(() => {
    checkStatus()
  }, [])

  // =============================================================
  //                      WALLET MANAGEMENT
  // =============================================================

  const getWallet = async () => {
    // We are always working with the first spot in the database.
    return await db.blobs.toCollection().first()
    // if we have the local data saved, we return it.
    // if (walletData) { return walletData }
    // // Else we got nada, abd return as such
    // else { return undefined }
  }

  interface WalletData {
    privateKey: `0x${string}`,
    account: PrivateKeyAccount
  }

  const createWallet = async (password: string, privateKey?: `0x${string}`): Promise<[null | WalletData, null | Errors]> => {
    try {
      // We need to check whether a local wallet blob already exist
      // If it does, we have made mistake somewhere and we need to stop
      // we need to prevent our mistake from overriding the already
      // saved data.
      if (!await getWallet()) return [null, Errors.NO_WALLET_DATA]
      // If privateKey is supplied, we use it, otherwise we generate one
      const _privateKey = privateKey || generatePrivateKey();
      // Create account from PK
      const _account = privateKeyToAccount(_privateKey);
      // Encrypt the private key
      const _blob = await encrypt(password, {
        address: _account.address,
        privateKey: _privateKey
      })
      // Add the created blob to the database
      await db.blobs.put({ id: BLOB_ID, address: _account.address, blob: _blob });
      // And, we're saving the last used / created account for convenience.
      localStorage.setItem(Storage.LAST_ACC, _account.address)
      // Return the account without err
      return [{
        account: _account,
        privateKey: _privateKey
      }, null]
    } catch (e) {
      return [null, Errors.SOMETHING_WRONG]
    }
  }

  const setWallet = (account: PrivateKeyAccount, privateKey: string) => {
    // Set the PK to the memory
    memory.set(WALLET_KEY, privateKey);
    // Set the account localy
    setAccount(account)
    // Set the shortened address localy
    setAddressShort(getShortAddress(account.address))
    // We're setting the last used account
    setLastAccount(account.address)
  }

  // const loadWallet = async (walletBlob: Blob, password: string) => {
  //   try {
  //     const { address, privateKey } = await decrypt(password, walletBlob.blob) as IBlob
  //     // We need to create the account that will be stored locally
  //     const _account = privateKeyToAccount(privateKey);
  //     // Address check. To make sure the data has not been adjusted
  //     if (address !== _account.address) return undefined
  //     // Seting the wallet into local states and memory
  //     setWallet(_account, privateKey)
  //     // Return value
  //     return [{
  //       account: _account,
  //       privateKey: privateKey
  //     }, null]
  //   } catch (e) {
  //     return [null, Errors.SOMETHING_WRONG]
  //   }
  // }

  const exportWallet = async (password: string): Promise<[null | `0x${string}`, null | Errors]> => {
    try {
      // First we're getting the blob
      const blobData = await getWallet()
      // We get the memory values
      const memoryPrivateKey = memory.get(WALLET_KEY);
      // We check we have all the required data
      if (!blobData || !account || !memoryPrivateKey) return [null, Errors.MISSING_DATA]
      // Then we try to decrypt the data
      const decryptedData = await decrypt(password, blobData.blob) as IBlob
      // Then we check all the information match
      // I'm honestly not sure if all these checks are necessary,
      // but I would like to check that all the information is legit
      // and there is no missmatch in the data.
      if (decryptedData.address !== blobData.address) return [null, Errors.MALFORMED_DATA]
      // Other check
      if (decryptedData.address !== account?.address) return [null, Errors.MALFORMED_DATA]
      // Check the private keys
      if (decryptedData.privateKey !== memoryPrivateKey) return [null, Errors.MALFORMED_DATA]
      // Then we return the information
      return [decryptedData.privateKey, null]
    } catch (e) {
      return [null, Errors.SOMETHING_WRONG]
    }
  }

  const deleteWallet = async (): Promise<[null | boolean, null | Errors]> => {
    try {
      // Removing the account from memory
      memory.delete(WALLET_KEY);
      // deleting the account
      await db.blobs.where('id').equals(BLOB_ID).delete()
      // Set the account localy
      setAccount(null)
      // Set the shortened address localy
      setAddressShort(null)
      // Deleting last account reference
      setLastAccount(null)
      // We also delte all data saved in terminal
      await db.terminal.clear()
      // set last acc to nothing
      localStorage.setItem(Storage.LAST_ACC, '')
      // We also nneed to set a state
      setSessionState('no_account')
      return [true, null]
    } catch {
      return [false, Errors.SOMETHING_WRONG]
    }
  }

  // =============================================================
  //                 WALLET SESSION MANAGEMENT
  // =============================================================

  const createWalletSession = async (address: `0x${string}`, privateKey: `0x${string}`) => {
    try {
      // Current time stamp
      const timestamp = Date.now()
      // Encrypt the private key, timestamp, and address
      const _blob = await encrypt(CONFIG.SESSION_KEY, {
        address: address,
        privateKey: privateKey,
        timestamp: timestamp
      })
      // Add the created blob to the database. Overriding the last session
      await db.sessions.put({ id: 0, timestamp: timestamp, blob: _blob });
      // And finally we return whether we have been successfull
      return true
    } catch (e) {
      return false
    }
  }

  const restoreWalletSession = async () => {
    try {
      // Check whether we have a session stored
      const session = await db.sessions.toCollection().first()
      // We bail if not
      if (!session) return null
      // Date.now calculation of a day
      const lastLogin = new Date(session.timestamp)
      // setting 24 hours in the future
      const now = new Date()
      // const day = 60 * 60 * 24 * 1000;
      // Current timestamp minus a da and a minute
      // const dayAgo = Date.now() - day;
      // Check whether the session is valid
      if (lastLogin.getHours() + 24 > now.getHours()) {
        // We can login now.
        const decryptedData = await decrypt(CONFIG.SESSION_KEY, session.blob) as IBlob
        // Then we create an account
        const _account = privateKeyToAccount(decryptedData.privateKey);
        // We're setting all the necessary states
        setWallet(_account, decryptedData.privateKey)
        // All went good
        return _account
      } else {
        // setSessionState('no_session')
        return null
      }
    } catch (e) {
      return null
    }
  }

  const deleteWalletSession = async () => {
    try {
      // Check whether we have a session stored
      const session = await db.sessions.toCollection().first()
      // We bail if not
      if (!session) return false
      // Proceed with deletion
      await db.sessions.where('id').equals(0).delete()
      // All went good
      return true
    } catch (e) {
      return false
    }
  }

  // =============================================================
  //                            ACCOUNT
  // =============================================================

  const createAccount = async (password: string, privateKey?: `0x${string}`): Promise<[null | PrivateKeyAccount, null | Errors]> => {
    const [tmpAccount, error] = await createWallet(password, privateKey)
    if (error) return [null, error]
    // TODO: Backend requests here, tracking the wallet on backend?
    if (tmpAccount) {
      if (privateKey) localStorage.setItem(Storage.BACKED_UP_WALLET, "true")
      // And we're changing the status
      setSessionState('no_session')
      // Finally returning the account
      return [tmpAccount.account, null]
    } else {
      setSessionState('no_account')
      return [null, error]
    }
  }

  const loginAccount = async (password: string, nonce: string): Promise<[null | PrivateKeyAccount, null | Errors]> => {
    try {
      // First we're getting the blob
      const blobData = await getWallet()
      // We check we have all the required data
      if (!blobData) return [null, Errors.MISSING_DATA]
      // Then we try to decrypt the data
      const decryptedData = await decrypt(password, blobData.blob).catch((e) => {
        return null
      }) as IBlob | null
      // We bail if no data
      if (decryptedData === null) return [null, Errors.WRONG_PASSWORD]
      // Then we create an account
      const _account = privateKeyToAccount(decryptedData.privateKey);
      // We're creating a message to sent
      const message = new SiweMessage({
        domain: window.location.host,
        address: _account.address,
        statement: 'Sign in with Ethereum to the app.',
        uri: window.location.origin,
        version: '1',
        chainId: Number(CONFIG.CHAIN_ID),
        nonce: nonce,
      })
      // Creating a signature to send
      const signature = await walletClient.signMessage({
        account: _account,
        message: message.prepareMessage()
      })
      // Verifying the signature via request
      const session = await verifySignature(signature, message)
      // Checking whether the request went fine or not.
      if (session === null) return [null, Errors.SERVER_UNAVAILABLE]
      if (session.ok === false) return [null, Errors.UNKNOWN_ERROR]
      // Only if everything is allright, we set the content
      setWallet(_account, decryptedData.privateKey)
      // And we're changing the status
      setSessionState('active_session')
      // We're also creating a temporary session
      await createWalletSession(_account.address, decryptedData.privateKey)
      // Then we return the information
      return [_account, null]
    } catch (e) {
      return [null, Errors.SOMETHING_WRONG]
    }
  }

  const logoutAccount = async (): Promise<[null | boolean, null | Errors]> => {
    try {
      // First we need to try removing the cookie
      const signout = await getSignout()
      if (!signout) return [false, Errors.SERVER_UNAVAILABLE]
      // Check whether we have a session stored
      await deleteWalletSession()
      // Set the PK to the memory
      memory.delete(WALLET_KEY);
      // Set the account localy
      setAccount(null)
      // Set the shortened address localy
      setAddressShort(null)
      // Delete current sess
      setSessionState('no_session')
      // Finally we return if we succeeded
      return [true, null]
    } catch (e) {
      return [false, Errors.SOMETHING_WRONG]
    }
  }

  // =============================================================
  //                            PROVIDER
  // =============================================================

  return (
    <AccountContext.Provider value={{
      account,
      addressShort,
      sessionState,
      lastAccount,
      balance,
      prestige,

      getWallet,
      exportWallet,
      deleteWallet,

      createAccount,
      loginAccount,
      logoutAccount
    }}>
      {props.children}
    </AccountContext.Provider >
  )
}