import * as React from 'react';

// =============================================================
//                          LOCAL
// =============================================================

import styles from './p.module.css'

// =============================================================
//                          TYPINGS
// =============================================================

type ParagraphProps = {
  theme?: OS.Theme
} & React.HTMLAttributes<HTMLParagraphElement>

const Themes = {
  primary: styles.primary,
  secondary: styles.secondary,
  accent: styles.accent
}

// =============================================================
//                          COMPONENT
// =============================================================

export const P = (ogProps: ParagraphProps) => {
  const { theme, style, ...props } = ogProps

  const themeSelected = theme || 'accent'

  const classNames = [
    styles.paragraph,
    Themes[themeSelected],
    props.className
  ].join(' ')

  return (<p className={classNames} style={style} {...props} />);
}