// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes hUqsJSKlT5tfo6e2xu9N {
  0% {
    top: 5%;
    height: 90%;
  }

  50%,
  100% {
    top: 25%;
    height: 50%;
  }
}

.McjtHTYbV9Q5lcysDok8 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 100% !important;
}

.RAscoYPeWM3DBPEb7djc {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.vs03NqM0CDJsyYBiFmsY {
  &>* {
    background-color: rgba(var(--c-accent-600));
  }
}

.rJpaYrGeBPx6O4qt9EI2 {
  &>* {
    background-color: rgba(var(--c-primary-600));
  }
}

.QdJwJa87hPPFRQLcRi3G {
  &>* {
    background-color: rgba(var(--c-secondary-600));
  }
}

.hoFYxHeReXs8fjaun5Pm {
  display: inline-block;
  width: 2%;
  animation: hUqsJSKlT5tfo6e2xu9N 0.8s cubic-bezier(0, 0.5, 0.5, 1) infinite;

  &:not(:last-of-type) {
    margin-right: 4px;
  }
}

.zcgSUQhVu5MpdA39dENh {
  animation-delay: -0.24s;
}

.CXF8fexGqT1QBaAHL8KU {
  animation-delay: -0.12s;
}

.henPPZQDcYlgATmUMTOY {
  animation-delay: 0s;
}`, "",{"version":3,"sources":["webpack://./src/components/common/loader/loader.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,OAAO;IACP,WAAW;EACb;;EAEA;;IAEE,QAAQ;IACR,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,2CAA2C;EAC7C;AACF;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE;IACE,8CAA8C;EAChD;AACF;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,0EAA6D;;EAE7D;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["@keyframes animate {\n  0% {\n    top: 5%;\n    height: 90%;\n  }\n\n  50%,\n  100% {\n    top: 25%;\n    height: 50%;\n  }\n}\n\n.loaderFullPage {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100% !important;\n  height: 100% !important;\n}\n\n.loaderCenterContent {\n  display: flex;\n  position: relative;\n  justify-content: center;\n  align-items: center;\n}\n\n.accent {\n  &>* {\n    background-color: rgba(var(--c-accent-600));\n  }\n}\n\n.primary {\n  &>* {\n    background-color: rgba(var(--c-primary-600));\n  }\n}\n\n.secondary {\n  &>* {\n    background-color: rgba(var(--c-secondary-600));\n  }\n}\n\n.loaderBar {\n  display: inline-block;\n  width: 2%;\n  animation: animate 0.8s cubic-bezier(0, 0.5, 0.5, 1) infinite;\n\n  &:not(:last-of-type) {\n    margin-right: 4px;\n  }\n}\n\n.loaderBarFirst {\n  animation-delay: -0.24s;\n}\n\n.loaderBarSecond {\n  animation-delay: -0.12s;\n}\n\n.loaderBarThird {\n  animation-delay: 0s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderFullPage": `McjtHTYbV9Q5lcysDok8`,
	"loaderCenterContent": `RAscoYPeWM3DBPEb7djc`,
	"accent": `vs03NqM0CDJsyYBiFmsY`,
	"primary": `rJpaYrGeBPx6O4qt9EI2`,
	"secondary": `QdJwJa87hPPFRQLcRi3G`,
	"loaderBar": `hoFYxHeReXs8fjaun5Pm`,
	"animate": `hUqsJSKlT5tfo6e2xu9N`,
	"loaderBarFirst": `zcgSUQhVu5MpdA39dENh`,
	"loaderBarSecond": `CXF8fexGqT1QBaAHL8KU`,
	"loaderBarThird": `henPPZQDcYlgATmUMTOY`
};
export default ___CSS_LOADER_EXPORT___;
